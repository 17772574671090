
/* eslint-disable */
import { computed, defineComponent, onMounted, ref } from 'vue';
import annotationPlugin from 'chartjs-plugin-annotation';
import { useScanStore } from '@/stores/scan';
import { useLabelsStore } from '@/stores/labels';
import { Information } from '@/constants/Information';
import MetricsList from '@/components/ui/MetricsList.vue';
import { TabTypes } from '@/../src/constants/TabTypes';
import * as math from 'mathjs';
import { Chart, ChartComponentLike, Plugin } from 'chart.js';
import ScanAnalyzer from '@/utility/flowgraph';
import { getDefaultFontStyle } from '@/utility/helpers';

export default {
  name: 'FlowGraph',
  components: { MetricsList },
  props: {
    previewReport: {
      type: Boolean,
      value: false,
    },
    showInformationModal: {
      type: Boolean,
      required: false,
      default: true,
    },
    showTitle: {
      default: true,
    },
  },
  setup() {
    const flowGraph = ref(null);
    const scan = useScanStore();
    const getScan = computed(() => scan.getScan);
    const labelsStore = useLabelsStore();
    const flowHeader = computed(() => labelsStore.getLabels?.flowOscillatory?.header);
    const flowTitle = computed(() => labelsStore.getLabels?.flowOscillatory?.fog);
    const fosTitle = computed(() => labelsStore.getLabels?.flowOscillatory?.fos);

    onMounted(() => {
      const flowOscillatoryMetrics = getScan?.value?.flowOscillatoryMetrics;
      if (!flowOscillatoryMetrics) {
        console.error('Flow oscillatory metrics are not available');
        return;
      }

      const flowGraph = new ScanAnalyzer(flowOscillatoryMetrics);
      const initialLineData = flowGraph.calculateInitialLine();
      const secondLineData = flowGraph.calculateSecondLine(initialLineData.highestX, initialLineData.highestY);

      Chart.register({
        id: 'customAxis',
        beforeDraw: function (chart) {
          flowGraph.drawColorBands(chart, initialLineData.lineOne, secondLineData.lineTwo, flowGraph.FlowGraphConfig.colorBands, flowGraph.FlowGraphConfig.colorBandLines);
          flowGraph.drawCustomAxis(chart, flowGraph.FlowGraphConfig.colorBands);
          flowGraph.drawMarkerPoint(chart);
        }
      });

      const canvasElement = document.getElementById('flowGraph') as HTMLCanvasElement | null;

      if (!canvasElement) {
        console.error("Canvas element not found");
        return;
      }
      const ctxTwo = canvasElement.getContext('2d');

      if (!ctxTwo) {
        console.error("Failed to get 2D context for canvas");
        return;
      }


      let range = initialLineData.highestX - initialLineData.lowestX;
      let rangeY = initialLineData.highestY - initialLineData.lowestY;

      let desiredTickCount = 10;
      let stepSize = range / desiredTickCount;
      let stepSizeY = rangeY / desiredTickCount;

      Chart.register(annotationPlugin);
      getDefaultFontStyle();

      const data = {
        datasets: [

          {
            label: 'Flow Oscillatory Line',
            data: initialLineData.lineOne,
            borderColor: 'black',
            fill: '-1',
            backgroundColor: 'rgba(173, 216, 230, 0.5)', // blue color
            borderDash: [5, 5],
            pointRadius: 0,
            borderWidth: 2,
          },
          {
            label: 'Upper Limit Line',
            data: secondLineData.lineTwo,
            borderColor: 'rgba(0, 0, 255, 0)', // transparent color
            borderWidth: 2,
            fill: false,
            showLine: true,
            pointRadius: 0
          }

        ]
      };

      const labelsStore = useLabelsStore();

      const xAxis = computed(() => labelsStore.getLabels?.flowOscillatory?.xAxis);
      const yAxis = computed(() => labelsStore.getLabels?.flowOscillatory?.yAxis);
      const config = {
        type: 'line',
        data: data,
        options: {
          responsive: true,
          plugins: {
            legend: {
              position: 'top',
              display: false
            }
          },
          scales: {
            x: {
              title: {
                display: true,
                text: xAxis.value,

              },
              grid: {
                display: false,
              },
              type: 'linear',
              ticks: {
                stepSize: stepSize,
              },
              min: initialLineData.lowestX,
              max: initialLineData.highestX
            },
            y: {
              title: {
                display: true,
                text: yAxis.value
              },
              grid: {
                display: false,
              },
              ticks: {
                stepSize: stepSizeY,
              },
              min: initialLineData.lowestY,
              max: initialLineData.highestY,

            },

            'x-opposite': {
              position: 'top',
              grid: {
                drawBorder: true,
                drawOnChartArea: false,
              },
            },
            'y-opposite': {
              position: 'right',
              grid: {
                drawBorder: true,
                drawOnChartArea: false,
                color: 'transparent',
              },
              ticks: {
                display: false,
                tickLength: 0
              },
            },

          },
          animation: false
        },

      };

      // @ts-ignore
      const flowOscillatoryChart = new Chart(ctxTwo, config);

    })

    return { flowGraph, getScan, flowTitle, Information, fosTitle, flowHeader, TabTypes };
  }
};
